/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h3: "h3",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "44bits 팟캐스트 120번째 로그에서는 44bits 팟캐스트 120.log : 인덴트코퍼레이션 프리A 투자, CodeCov 깃헙 토큰 유출, Google 검색결과에 AMP 가산점 제거에 대해서 이야기를 나누었습니다."), "\n", React.createElement(_components.h3, null, "인덴트코퍼레이션 프리A 투자 유치"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://platum.kr/archives/163504"
  }, "‘브이리뷰’ 운영사 인덴트코퍼레이션, 45억 규모 프리A 투자 유치")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.hankyung.com/finance/article/2021052627701"
  }, "야놀자 나스닥 직행…손정의 2조원 투자 | 한경닷컴 - hankyung.com")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.notion.so/Indent-Corp-Recruit-3ca9a9ddb2274acb8f119ad972d49cce"
  }, "인덴트코퍼레이션 채용 페이지")), "\n"), "\n", React.createElement(_components.h3, null, "CodeCov 깃헙 토큰 유출"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://about.codecov.io/"
  }, "Codecov - The Leading Code Coverage Solution")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://about.mercari.com/en/press/news/articles/20210521_incident_report/"
  }, "Mercari’s Response to the Codecov Vulnerability and Related Notification on Personal Information Exposure | Mercari, inc")), "\n"), "\n", React.createElement(_components.h3, null, "Google 검색결과에 AMP 가산점 제거"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://developers.google.com/search/blog/2021/04/more-details-page-experience"
  }, "More time, tools, and details on the page experience update - Google Developers")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://web.dev/lcp/"
  }, "Largest Contentful Paint (LCP) - web.dev")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://web.dev/fid/"
  }, "First Input Delay (FID) - web.dev")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://web.dev/cls/"
  }, "Cumulative Layout Shift (CLS) - web.dev")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
